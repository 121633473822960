/* 2.2 Border ======================================================= */
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $prop, $abbrev in (top: t, right: r, bottom: b, left: l) {
            .border#{$infix}-#{$abbrev} {
                border-#{$prop}: 1px solid $border-color;
            }
        }
    }
}

// Shadows
@each $i, $color in $theme-colors {
    .shadow-#{$i} {
        box-shadow: 0 1px 10px rgba($color, 0.4) !important;
    }
}

.shadow {
    box-shadow: 0 1px 9px 1px rgba(0,0,0,.15);
}

.drop-shadow {
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, .25));
}

.shadow-box {
    transition: box-shadow .3s linear;
    box-shadow : 0 0 1px rgba(0, 0, 0, .1), 0 2px 6px rgba(0, 0, 0, .03);
}

.shadow-hover {
    transition: box-shadow .3s ease-in-out;
    @include hover-shadow(#000, 3px, 15px, .085);
}

.border-input {
    border: 1px solid $input-border-color;
}

.rounded-circle img {
    border-radius: 50%;
}

.bw-2 {
    border-width: 2px;
}