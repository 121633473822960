.features {
  h2 {
    font-weight: 700;
  }
  p {
    color: #6c757d
  }
  .features-box {
    cursor: pointer;
  }
}
.exchanges {
  width: 90%;
  margin: auto !important;
}

.inner-container {
  width: 80%;
  margin: auto !important;
}

.title-placement {
  margin-top: 50px;
}
.alter3-features {
  .btn {
    color: #fff;
    background: #ed3426ff;
    border: #ff6f61 solid 1px;
    font-weight: 700;
    border-radius: 5px;
    font-size: 14px;
    padding: 0.3rem 0.7rem;
    width: fit-content;
    &:hover {
      color: #fff;
    }
    .icon {
      vertical-align: inherit;
    }
  }
  .shapes-container {
    overflow: unset;
  }

  .rotated-cards {
    @include media-breakpoint-up(lg) {
      transform: rotate(-5deg);
    }
  }

  .shape {
    position: absolute;

    &-ring {
      right: 0;
      top: 0;
      transform: translate3d(50%, -50%, 0);

      &-1 {
        .animation {
          @include ring-variant(340px, $alter3-features--ring-1-width, $alter3-features--ring-1-color);
          animation-duration: 45s;
        }
      }

      &-2 {
        .animation {
          @include ring-variant(280px, $alter3-features--ring-2-width, $alter3-features--ring-2-color);
          animation-duration: 60s;
        }
      }

      .animation {
        border-style: dashed;
      }
    }

    &-circle {
      top: 50px;
      right: 120px;

      div {
        @include circle-variant(15px, $alter3-features--circle-color);
      }
    }
  }

  .background-shape-main {
    z-index: 0;

    left: 0;
    top: 10%;
    width: 500px;
    height: 720px;
    transform: translate3d(-25%, 20%, 0) rotate(-30deg);
    background-image: linear-gradient(35deg, $white 0, $beluga-light-5x 100%);

    @include media-breakpoint-up($grid-float-breakpoint) {
      width: 1200px;
      top: 0;
    }
  }
}
