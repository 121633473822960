@mixin font-awesome($icon: '', $style: solid) {
    font-family: $font-family-awesome; // shortening font declaration
    //font-size: inherit; // can't have font-size inherit on line above, so need to override
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;

    @if $style == solid {
        font-weight: 900;
    } @else {
        font-weight: 400;
    }

    @if $icon != '' {
        content: fa-content($icon);
    }
}
