@include keyframes (clockwise) {
  0%{
    transform: rotate(0deg) translate(-165px) rotate(0deg);
  }
  100%{
    transform: rotate(360deg) translate(-165px) rotate(-360deg);
  }
}

@include keyframes (anti-clockwise) {
  0%{
    transform: rotate(0deg) translate(165px) rotate(0deg);
  }
  100%{
    transform: rotate(-360deg) translate(165px) rotate(360deg);
  }
}

@include keyframes (rotating) {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(-360deg);
  }
}

@include keyframes (rotating-diagonal) {
  0% {
    transform: translate(-300px, 150px)  rotate(0deg);
  }
  100% {
    transform: translate(300px, -150px)  rotate(180deg);
  }
}

.animation {
  &--clockwise {
    @include animation(clockwise, 30s);
  }

  &--anti-clockwise {
    @include animation(anti-clockwise, 30s);
  }

  &--rotating {
    @include animation(rotating, 15s);
  }

  &--rotating-diagonal {
    @include animation(rotating-diagonal, 15s, linear, infinite, alternate);
  }
}
