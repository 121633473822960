/* 6.3 Footer ======================================================= */

.site-footer {
    font-size: .9em;
    padding-top: 10px;
    background-color: #23292E !important;
    color: #D8D8D8 !important;
    font-size: 14px;
    padding-bottom: 20px;

    h4 {
        margin-bottom: $padding-default / 4;
    }
    H6 {
      color: #ffffff;
      text-transform: uppercase;
      font-size: 14px;
    }

    .logo {
        max-height: 56px;
    }
    .icon {
      width: 30px;
      margin-right: 0px;
    }
    .nav-link {
      padding: 0;
      padding-bottom: 0px !important;
    }

    a {
    color: #D8D8D8 !important;
    }
    .text-center {
      font-size: 14px;
    }
    .buffer {
      padding-right: 20px;
    }
    .small {
      padding-top: 20px;
    }
}
