.alter3-header {
  .container {
    padding-bottom: 20rem;

    @include media-breakpoint-up($grid-float-breakpoint) {
      padding-bottom: 15rem;
    }
  }
  h1 {
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -0.02em;
  }

  .bring-to-front {
    color: $beluga-dark-4x;
    @media (min-width: 1440px){
      max-width: 1620px;
      padding-top: 200px;
      padding-bottom: 130px;
    }
  }

  .aurlix-color {
    color: #ed3426ff;
  }

  .btn-rounded {
    color: #ed3426ff;
    border: #ff6f61 solid 1px;
    font-weight: 700;
    border-radius: 5px;
    font-size: 14px;
    padding: 0.6rem 1.25rem;

    &:hover{
      background-color: #ff6f61;
      color: #fff;
    }
  }
  .btn-colored {
    color: #fff;
    background: #27333e;
    border: #27333e solid 1px;
    font-weight: 700;
    border-radius: 20px;
    font-size: 14px;
    padding: 0.5rem 0.4rem 0.5rem 1.2rem;

    &:hover{
      background-color: #ff4c3b;
      border-color: #ff412e;
      color: #ffffff;
    }
  }

  .monitor {

    .under {
      position: absolute;
      left: 73px;
      top: -87px;
      height: 427px;
      width: 803px;
      z-index: -1;
    }

    .over {
      position: absolute;
      left: 50px;
      top: -110px;
      height: 650px;
      z-index: 1;
    }
  }

  .animation {
    &-shape {
      position: absolute;

      &.shape {
        &-triangle {
          top: 50%;
          left: 50%;

          div {
            opacity: .1;
            @include triangle-variant(15px, $white);
          }
        }

        &-ring {
          top: 75%;
          left: 25%;

          div {
            @include ring-variant(10px, 5px, theme-color("primary"));
          }
        }

        &-circle {
          top: 20%;
          left: 50%;

          div {
            @include circle-variant(15px, rgba(theme-color("light"), 0.3));
          }
        }

        &-diamond {
          top: 35%;
          left: 25%;

          div {
            opacity: .5;
            @include diamond-variant(10px, theme-color("alternate"));
          }
        }
      }
    }
  }

  .static {
    &-shape {
      position: absolute;

      &.shape {
        &-ring {
          &-1 {
            @include ring-variant(40px, 15px, rgba($white, .5));
            top: 15%;
            left: 0;
            transform: translateX(-50%);
          }

          &-2 {
            @include ring-variant(60px, 15px, rgba($white, .5));
            top: 5%;
            right: 0;
            transform: translateX(50%);
          }
        }

        &-circle {
          &-1 {
            @include circle-variant(120px, rgba($white, .09));
            top: 22%;
            left: 22%;
          }
        }
      }

      &.pattern {
        &-dots {
          &-1 {
            top: 0;
            right: 0;
            width: 250px;
            height: 50%;
            @include pattern-dots(3px, 30px, rgba($white, .1));
          }

          &-2 {
            left: 0;
            bottom: 0;
            width: 150px;
            height: 20%;
            @include pattern-dots(3px, 30px, rgba($white, .1));
          }
        }
      }

      &.ghost-shape {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: $shape-bg-border-radius;

        &-1 {
          top: 0;
          transform: translate3D(30%, -93%, 0) skewX(35deg) rotate(-12deg);
          background-color: $shape-bg-1--bg-color;
          max-height: $shape-bg-1--max-height;
          box-shadow: $shape-bg-1--box-shadow;
        }
      }
    }
  }
}
