$rotate-md: -12deg;
$mock-xl-width: 829px;
$device-margin: 50px;

.lightweight-template .learn-more {
  margin-bottom: 18rem;
}

.isometric-mockups {
  pointer-events: none;
  position: absolute;
  display: flex;
  align-items: center;
  left: 50%;
  margin-left: 85px;
  transform: scale(.5) rotate($rotate-md) translateX(-50%);
  transform-origin: 0 20%;
  z-index: 3;
  top: 340px;

  > div {
    margin: 0 50px;
  }

  .ipad {
    max-width: 100%;
  }

  .phone-big {
    display: none;
  }

  .phone-small {
    order: -1;
    width: 225px;
  }

  .tablet {
    &.landscape {
      width: 512px;
      margin: 0;
    }

    &.portrait {
      width: 450px;
      margin-top: 0;
    }
  }

  @include media-breakpoint-up(sm) {
    margin-left: 0;
  }

  @include media-breakpoint-up(md) {
    top: 40px;
    flex-wrap: wrap;
    transform: rotate($rotate-md);
    transform-origin: 100% 0;
    width: $mock-xl-width;
    margin-left: -10px;

    .tablet {
      margin: 0!important;
      max-width: 100%;
    }

    .lightweight-template .learn-more {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(md) {
    > div {
      margin: 0 auto;
    }

    .tablet {
      &.landscape {
        align-self: flex-end;
        margin-right: 50px;
        transform: translateX(0);
      }

      &.portrait {
        display: flex;
        width: 450px;
        margin-top: 50px !important;
      }
    }

    .phone-big {
      display: flex;
      width: 267px;
    }

    .phone-small {
      order: 0;
      align-self: flex-start;
      margin: 50px 50px 0 0;
    }
  }
}
