// Compile BootStrap and make it part of the main style file
// This will allow you to override any BS default variable to fit your needs

/* ==========================================================================
    [1. CUSTOM BOOTSTRAP COMPILATION]
========================================================================== */
@import "~bootstrap/scss/bootstrap";
@media (min-width: 1200px){
  .container {
    max-width: 1320px;
  }
}
