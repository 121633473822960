.navbar-nav {
  position: relative;
}

.navbar {
  @include media-breakpoint-up($grid-float-breakpoint) {
    .dropdown {
      &.mega {
        position: static;
      }
    }
  }
}
